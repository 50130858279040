import React, {useState} from 'react';
import { Link, useParams } from "react-router-dom";
import { useStateValue } from '../StateProvider';
import {Helmet} from "react-helmet";
import { ArrowBack } from "@material-ui/icons";
import styled from "styled-components";
import Announcement from "../components/Announcement";
import Navbar from "../components/Navbar";
import { mobile } from "../responsive";
import { popularProducts } from "../data";
import ProductRecommended from '../components/ProductRecommended';
import GoToTop from "../components/GoToTop";
import FooterLinks from "../components/Footer/FooterLinks";
import Social from "../components/Footer/SocialLinks";
import Copyright from "../components/Footer/Copyright";
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import "./Product.css";
import ReturnPolicy from '../components/ReturnPolicy';

const Container = styled.div``;

const Wrapper = styled.div`
  padding: 50px;
  display: flex;
  ${mobile({ padding: "10px"  })}
`;

const ImgContainer = styled.div`
  flex: 1;
  ${mobile({ width:"100%" })}
`;

const InfoContainer = styled.div`
  flex: 1;
  padding: 0px 50px;
  ${mobile({ padding: "20px 0", width:"100%", display:"column", textAlign: "center" })}
`;

const Title = styled.h1`
  font-weight: 200;
`;

const Desc = styled.p`
  margin: 20px 0px;
`;

const Price = styled.span`
  font-weight: 100;
  font-size: 40px;
`;

const FilterContainer = styled.div`
  width: 95%;
  margin: 30px 0px;
  display: column;
  justify-content: space-between;
  ${mobile({ width: "100%" })}
`;

const Filter = styled.div`
  display: flex;
  align-items: center;
`;

const FilterTitle = styled.span`
  font-size: 20px;
  font-weight: 200;
  ${mobile({ textAlign: "center", justifyContent: "center", margin: "0 auto" })}
`;

const FilterSize = styled.select`
  margin-left: 10px;
  padding: 5px;
`;

const FilterSizeOption = styled.option``;

const AddContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${mobile({ width: "100%", margin: "0 auto", justifyContent: "center", textAlign: "center" })}
`;

const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
`;

const Amount = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 7px;
  border: 1px solid #390072;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 5px;
`;

const Button = styled.button`
  padding: 15px;
  border: 2px solid #390072;
  background-color: white;
  cursor: pointer;
  font-weight: 500;
  border-Radius: 10px;
  margin-left: 20px;

  &:hover{
      background-color: #390072;
      color: white;
  }
`;

const ContainerImg = styled.div`
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const ImageSlider = styled.img`
    width: 100%;
    cursor: pointer;
    transition: all 0.5s ease;

    &:hover{
      transform: scale(1.05);
      transition: all 0.5s ease;
    }
`;

const ImageBox = styled.div`
    flex: 1;

    ${mobile({  })}
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Texth2 = styled.h2`
    fontWeight: bold;
    padding: 20px 0;
    textAlign: center;

    ${mobile({ padding: "40px", textAlign: "center" })}
`;

const Product = ( {id, productCod, productName, productTheme, /* quantity, */ color, /* selectedSize, */ imageUrl, currency, price } ) => {

  const [{carrito}, dispatch] = useStateValue();
  
  const { code } = useParams();
  const a = popularProducts.filter((item, key) => 
  (item.productCod).includes(code));
  const b = a[0]?.productTheme;
  const [selected, setSelected] = useState(a[0]?.imageUrl);
  const [quantity, setQuantity] = useState(1);
  const [selectedSize, setSelectedSize] = useState("M");

  const addToCarrito = () => {
    dispatch({
    type: 'ADD_TO_CARRITO',
      item: {
        id: a[0]?.id,
        productCod: a[0]?.productCod,
        title: a[0]?.productName,
        theme: a[0]?.productTheme,
        quantity: quantity,
        color: a[0]?.color,
        size: selectedSize,
        image: a[0]?.imageUrl,
        currency: a[0]?.currency,
        price: a[0]?.price,
      }})
  }

  const handleChangeSize = (e) => {
    setSelectedSize(e.target.value)
  }

  const handleDecrement = () => {
    if (quantity >= 2) {
      setQuantity(quantity-1)
    }
  }

  const handleIncrement = () => {
    setQuantity(quantity+1)
  }

  return (
    <Container>
      <Announcement />
      <Navbar />
      <Link to="/" style={{ color:"#111", textDecoration: 'none'}} >
        <div className="continueshopping"  >
          <ArrowBack/>
          <p>Sigue comprando</p>
        </div>
      </Link>

      <Wrapper className='product__wrapper' >
        {popularProducts
        .filter((item, key) => 
        (item.productCod).includes(code))
        .map((item) => (
          <div className='product__wrapper__container' style={{ display: "flex"}} key={item.id} >
          <Helmet>
              <title>{item.productTheme} &bull; {item.productName}</title>
              <link rel="canonical" href={"https://shop.teleboing.com/product/" + code} />
              <meta name='description' content={item.description} />
              <meta name="keywords" content="peliculas, series, humor, clothing, ropa" />
              <meta property='image' content={item.imageUrl} />
              <meta property="og:title" content={item.productTheme + " | " + item.productName} />
              <meta property="og:type" content="website" />
              <meta property="og:image" content={item.imageUrl} />
              <meta property="og:url" content={"https://store.demoqrata.com/product/" + code}/>
              <meta name="twitter:card" content="summary_large_image"/>
              <meta property="og:description" content={item.description} />
              <meta property="og:site_name" content="demoqrata"/>
              <meta name="twitter:image:alt" content={item.productTheme + " | " + item.productName} />
          </Helmet>
          <ImgContainer>
          <ImageContainer>
              <ImageBox>
                <ImageSlider onClick={(e) => setSelected(item.imageUrl)} src={item.imageUrl} className='small-img' width="100%" alt={item.productTheme} />
              </ImageBox>
              <ImageBox>
                <ImageSlider onClick={(e) => setSelected(item.imageExtra1Url)} src={item.imageExtra1Url} className='small-img' width="100%" alt={item.productTheme} />
              </ImageBox>
              <ImageBox>
                <ImageSlider onClick={(e) => setSelected(item.imageExtra2Url)} src={item.imageExtra2Url} className='small-img' width="100%" alt={item.productTheme} />
              </ImageBox>
              <ImageBox>
                <ImageSlider onClick={(e) => setSelected(item.imageExtra3Url)} src={item.imageExtra3Url} className='small-img' width="100%" alt="" />
              </ImageBox>
              <ImageBox>
                <ImageSlider onClick={(e) => setSelected(item.imageExtra4Url)} src={item.imageExtra4Url} className='small-img' width="100%" alt="" />
              </ImageBox>
          </ImageContainer>
          <InnerImageZoom src={selected} />
          </ImgContainer>

          <InfoContainer>
            <Title>{item.productName} &bull; </Title><Title style={{ fontWeight:"bold", paddingTop:"10px" }} >"{item.productTheme}" </Title>
            <p style={{ paddingTop:"20px" }} >Material: {item.productMaterial} </p>
            <span style={{ paddingTop:"20px", fontSize:"small" }} >Código: {item.productCod} </span>
            {/* <p style={{ paddingTop:"20px" }} >Artista Ilustrador: {item.artBy} </p> */}
          <Desc>
            <span style={{ fontWeight:"600" }} >Contexto:</span> {item.description}
          </Desc>
          <Price>S/ {item.price} <span style={{ fontSize:"1.8rem" }} >soles.</span></Price>
          
          <FilterContainer>
            <Filter style={{ paddingBottom:"0px" }} >
              <FilterTitle>Color: {item.color} </FilterTitle>
            </Filter>
            
            <Filter style={{ paddingBottom:"20px" }} >
              <Desc><span style={{ fontWeight:"600" }} >Medidas:</span> {item.productMeasure} </Desc>
            </Filter>

            <Filter>
            <FilterTitle>Elige una talla</FilterTitle>
            
            <FilterSize value={selectedSize} onChange={handleChangeSize} >
              <FilterSizeOption value="XS" >XS</FilterSizeOption>
              <FilterSizeOption value="S" >S</FilterSizeOption>
              <FilterSizeOption value="M" >M</FilterSizeOption>
              <FilterSizeOption value="L" >L</FilterSizeOption>
              <FilterSizeOption value="XL" >XL</FilterSizeOption>
              <FilterSizeOption value="XXL" >XXL</FilterSizeOption>
            </FilterSize>
            <p style={{ paddingLeft:"10px" }} >* Sólo cuando elijas un Polo</p>
          </Filter>
            
          </FilterContainer>

          <AddContainer >
            <AmountContainer>
              <button type="button" onClick={() => handleDecrement(item.id)} style={{ width:"30px", height:"30px", backgroundColor:"rgba(124,81,161,0.5)", borderRadius:"7px" }} >-</button>
              <Amount >{quantity}</Amount>
              <button type="button" onClick={() => handleIncrement(item.id)} style={{ width:"30px", height:"30px", backgroundColor:"rgba(124,81,161,0.5)", borderRadius:"7px" }} >+</button>
            </AmountContainer>

            <Button onClick={addToCarrito} >AGREGA AL CARRITO</Button>

          </AddContainer>
          <p style={{ backgroundColor:"rgba(124,81,161,0.3)", padding: "20px", marginTop:"50px", borderRadius:"10px", textAlign:"center" }} >Cómpralo ahora y recíbelo en <strong>72 horas</strong> en donde estés.</p>
        </InfoContainer>
        </div>
        ))}
      </Wrapper>

      <div>
      <InfoContainer>
        <Texth2>Otros productos que te pueden interesar</Texth2>
      </InfoContainer>
      <ContainerImg>
        {popularProducts
        .filter((item, key) => 
        (item.productTheme).includes(b))
        .map((item) => (
          <ProductRecommended item={item} key={item.id} />
        ))}
      </ContainerImg>
      </div>

      <ReturnPolicy />
      <Social />
      <FooterLinks />
      <Copyright />
      <GoToTop/>
    </Container>
  );
};

export default Product;
