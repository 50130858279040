import React, {useState} from 'react';
import  firebaseApp from "../components/ShoppingCart/firebase";
import "firebase/compat/firestore";
import { serverTimestamp } from "firebase/firestore";
import styled from "styled-components";
import "./Suggestion.css";

const Container = styled.div`
  height: auto;
  background-color: #111;
  /* background-color: #ff4500; */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 0;
`;

const Suggestion = () => {
  const db = firebaseApp.firestore();
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [suggestion, setSuggestion] = useState("");
  const [loader, setLoader] = useState(false);

  const handleSubmitSuggestion = async (e) => {
  e.preventDefault();
  setLoader(true);

  await db.collection("storeSuggestion")
    .add({
      nombre: nombre,
      email: email,
      suggestion: suggestion,
      timestamp: serverTimestamp(),
    })
    .then(() => {
      setLoader(false);
      alert("Sugerencia recibida. Muy pronto te sorprenderemos.");
    })
    .catch((error) => {
      alert(error.message);
      setLoader(false);
    });
  };

  return <Container className="suggestion">
  <div className="suggestion__container">
    <div className="suggestion__container__title">
      <h2>Coméntanos qué serie o película te gustaría en un polo y te avisaremos cuando lo tengamos en la tienda.</h2>
    </div>
    <div className="suggestion__container__form">
      <form className="suggestion__container__datos">
      <ul>
        <div className="suggestion__container__datospersonales">
          <li>
          <input type="text" placeholder='Nombre' value={nombre} onChange={(e) => setNombre(e.target.value)} />
          </li>

          <li>
          <input type="email" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
          </li>
        </div>
        <div className="suggestion__container__datossugerencias">
        <li>
        <input type="text" placeholder='Sugerencia. (ej: Euphoria, Moon Knight, Bridgerton, Ozark, The Walking Dead, How I Met Your Father...)' value={suggestion} onChange={(e) => setSuggestion(e.target.value)} />
        </li>
        </div>
      </ul>
      </form>
      <button 
          className="suggestion__container__enviar"
          type='submit' 
          style={{ background: loader ? "#111" : "#2d005b" }}
          onClick={handleSubmitSuggestion} >Envia tu sugerencia
      </button>
    </div>
  </div>
  </Container>;
};

export default Suggestion;
