import React from 'react';
import "./Copyright.css";

function Copyright() {
    return (
        
    <div className="copyright">
        <p>&#123; TELEBOING 2022 &#9679; plataforma audiovisual de producción y transmisión de contenido original. &#x7D;</p>
        <p>&#123; Una empresa de Unicornia.xyz ventures &#9679; representada legalmente en Latinoamérica por Insighter del Perú S.A.C. &#x7D;</p>
    </div>
        
    )
}

export default Copyright;
