import styled from "styled-components";
import "./Announcement.css";
import wsp from "../assets/wsp.svg";
import motorcycle from "../assets/motorcycle.svg";

const Container = styled.div`
  height: auto;
  background-color: #2f2f2f;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 0;
`;

const Announcement = () => {
  return <Container className="anuncio">
  <div className="anuncio__container">
    <div className="anuncio__container__left" style={{ display:"flex", padding:"0 20px", alignItems:"center", justifyContent:"center" }} >
      <span>¡Super Oferta!</span>
      <img 
        src={motorcycle}
        alt=""
        style={{ width:"30px", color:"white", padding:"0 5px" }}
      />
      <p>Envío gratis a todo el Perú si compras antes del 27 de Julio del 2022.</p>
    </div>
    <div className="anuncio__container__right" style={{ display:"flex", padding:"0 20px", alignItems:"center", justifyContent:"center" }}>
      <img 
        src={wsp}
        alt=""
        style={{ width:"20px", color:"white", padding:"0 5px" }}
      />
      <span>+51 981166709</span>
    </div>
  </div>
  </Container>;
};

export default Announcement;
