import styled from "styled-components";
import "./ReturnPolicy.css";
import { mobile } from "../responsive";

const Container = styled.div`
  height: auto;
  background-color: lightgray;
  color: #111;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  text-align:center;
  padding: 20px 60px;
  margin-top: 40px;
  ${mobile({ padding:"20px", height: "auto" })}
`;

const ReturnPolicy = () => {
  return <Container className="anuncio">Aceptamos la devolución del producto y efectuamos la entrega del dinero pagado en caso haya recibido el producto sucio, roto o si el empaque en el que fue entregado se encontrará abierto. Podemos también en lugar de coordinar la devolución, entregar un descuento de 15% en la siguiente compra.</Container>;
};

export default ReturnPolicy;
