import firebase from "firebase/compat/app";


const firebaseConfig = {
    apiKey: "AIzaSyDh2hywz555J-5H1yXIFczqHbXM9EznqS0",
    authDomain: "shop-teleboing.firebaseapp.com",
    projectId: "shop-teleboing",
    storageBucket: "shop-teleboing.appspot.com",
    messagingSenderId: "1069055153906",
    appId: "1:1069055153906:web:d950bd310a41944ab2a26b"
  };

  const firebaseApp = firebase.initializeApp(firebaseConfig);
    
  export default firebaseApp;
