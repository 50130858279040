import React, {useState} from 'react';
import { useStateValue } from '../../StateProvider';
import  firebaseApp from "./firebase";
import "firebase/compat/firestore";
import { serverTimestamp } from "firebase/firestore";
import "./Subtotal.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PaypalBox from "../PaypalBox";


function Subtotal() {
    const db = firebaseApp.firestore();
    const [{carrito}, dispatch] = useStateValue();
    const [nombre, setNombre] = useState("");
    const [apellidos, setApellidos] = useState("");
    const [telefono, setTelefono] = useState("");
    const [email, setEmail] = useState("");
    const [dni, setDni] = useState("");
    const [direccion, setDireccion] = useState("");
    const [ciudad, setCiudad] = useState("");
    const [pais, setPais] = useState("");
    const [referencia, setReferencia] = useState("");
    const [horario, setHorario] = useState("");
    const [medio, setMedio] = useState("");
    const [operacion, setOperacion] = useState("");
    const [fecha, setFecha] = useState("");

    const [loader, setLoader] = useState(false);

    const handleSubmitTLBNG = async (e) => {
        e.preventDefault();
        setLoader(true);
    
        await db.collection("storeOps")
          .add({
            nombre: nombre,
            apellidos: apellidos,
            telefono: telefono,
            email: email,
            dni: dni,
            direccion: direccion,
            ciudad: ciudad,
            pais: pais,
            referencia: referencia,
            horario: horario,
            medio: medio,
            operacion: operacion,
            fecha: fecha,
            carrito: carrito,
            sumatotal: sumatotal,
            timestamp: serverTimestamp(),
          })
          .then(() => {
            setLoader(false);
            alert("enviado");
            window.location.href = 'https://shop.teleboing.com/thanks';

          })
          .catch((error) => {
            alert(error.message);
            setLoader(false);
          });

    };

    let sumaparcial=0;
    let sumatotal=0;
    let productostotal=0;
    var i;
    var j;

    for (i=0; i<carrito.length; i++) {
        sumaparcial = carrito[i]?.quantity*carrito[i]?.price;
        sumatotal = sumatotal + sumaparcial;
    }

    var sumatotaldolar = (sumatotal/4.060).toFixed(2);

    for (j=0; j<carrito.length; j++) {
        productostotal = productostotal + carrito[j]?.quantity;
    }

    return (
        <div className="subtotal">
            <div>
                <h1 className="subtotal__title">Check-out.</h1>
            </div>

            <div className="subtotal__monto">
            <p>El monto total a pagar por {productostotal} producto(s) {/* en {carrito.length} orden(es)  */}es de <span>S/ {sumatotal}</span> soles o <span>USD$ {sumatotaldolar}</span> dólares.</p>
            </div>

            <div className="subtotal__metodosdepago">
                <h2>Opciones de pago</h2>

                <Tabs className="subtotal__metodosdepago__opt" defaultIndex={0}>
                    <TabList >
                        <Tab style={{ color:"#111" }}>Yape</Tab>
                        <Tab style={{ color:"#111" }}>Transferencia online</Tab>
                        <Tab style={{ color:"#111" }}>Paypal / Crédito o Débito</Tab>
                    </TabList>
                    <TabPanel>
                        <div className="subtotal__metodosdepago__img">
                        <img style={{padding:"0 10px", width:"75%"}} src="https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing_YAPE.webp?alt=media&token=b0f98a43-23b0-4e1a-ad24-85fbcd9c233a" alt="Yapea al: +51 942 137 078"/>
                        </div>
                    </TabPanel>
                    <TabPanel style={{paddingLeft:"20px",}}>
                        <img src="https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcontent%2Fbcp.svg?alt=media&token=2bcf37f2-dddb-452d-b77d-ec9763927451" style={{ width:"120px", paddingTop:"10px" }} alt="BCP" />
                        <p style={{paddingTop:"10px", fontSize:"1rem", color:"#111" }}>Banco de Crédito del Perú BCP</p>
                        <p style={{ color:"#111" }}>Cuenta de ahorros en soles</p>
                        <p style={{fontSize:"1.2rem", padding:"10px 0", color:"#111" }} >N° 194-97709184-0-42 / CCI N° 00219419770918404299</p>
                        <p style={{fontSize:"1rem", color:"#111" }}>SWIFT code: BCPLPEPL</p>
                        <p style={{ color:"#111" }}>Titular: INSIGHTER DEL PERU S.A.C.</p>
                        <p style={{ color:"#111" }}>RUC N° 20536307401</p>
                    </TabPanel>
                    <TabPanel>
                        <PaypalBox />
                    </TabPanel>
                </Tabs>
            </div>
            
            <div className="subtotal__datos">
                <h2>Formulario de datos de pago</h2>
                <form className="subtotal__datos__form" >
                <ul>
                    <li>
                    <input type="text" placeholder='Nombre' value={nombre} onChange={(e) => setNombre(e.target.value)} />
                    </li>

                    <li>
                    <input type="text" placeholder='Apellidos' value={apellidos} onChange={(e) => setApellidos(e.target.value)} />
                    </li>

                    <li>
                    <input type="text" placeholder='00 + Teléfono' value={telefono} onChange={(e) => setTelefono(e.target.value)} />
                    </li>

                    <li>
                    <input type="email" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </li>

                    <li>
                    <input type="text" placeholder='N° DNI/CE/Pasaporte' value={dni} onChange={(e) => setDni(e.target.value)} />
                    </li>

                    <li>
                    <input type="text" placeholder='Dirección de envío.' value={direccion} onChange={(e) => setDireccion(e.target.value)} />
                    </li>

                    <li>
                    <input type="text" placeholder='Ciudad' value={ciudad} onChange={(e) => setCiudad(e.target.value)} />
                    </li>

                    <li>
                    <input type="text" placeholder='País' value={pais} onChange={(e) => setPais(e.target.value)} />
                    </li>

                    <li>
                    <input type="text" placeholder='Alguna referencia para ubicar la dirección.' value={referencia} onChange={(e) => setReferencia(e.target.value)} />
                    </li>

                    <li>
                    <input type="text" placeholder='Mejor día y hora para recibir el pedido.' value={horario} onChange={(e) => setHorario(e.target.value)} />
                    </li>

                    <li>
                    <input type="text" placeholder='¿Qué medio de pago utilizó?' value={medio} onChange={(e) => setMedio(e.target.value)} />
                    </li>

                    <li>
                    <input type="text" placeholder='Nro de operación de pago.' value={operacion} onChange={(e) => setOperacion(e.target.value)} />
                    </li>

                    <li>
                    <input type="text" placeholder='Fecha y hora de pago.' value={fecha} onChange={(e) => setFecha(e.target.value)} />
                    </li>
                </ul>
                </form>
            </div>

            <button 
                className="subtotal__pagar"
                type='submit' 
                style={{ background: loader ? "#ccc" : " rgb(2, 2, 110)" }}
                onClick={handleSubmitTLBNG} >Enviar detalles de pago
            </button>

            <div>
                <p>Si tienes alguna pregunta o si quieres coordinar algún detalle de la entrega de tu pedido, no dudes en escribirnos por WhatsApp al: +51 981166709.</p>
            </div>
        </div>
    )
}

export default Subtotal;
