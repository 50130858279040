import styled from "styled-components";
import { popularProducts } from "../data";
import Product from "./Product";
import { mobile } from "../responsive";

const Container = styled.div`
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    ${mobile({ padding: "0" })}
`;

const Texth1 = styled.h1`
    padding: 80px 0 40px 50px;
    textAlign: center;
    justifyContent: center;

    ${mobile({ padding: "40px", textAlign: "center" })}
`;

const ProductsPosterpolo = () => {
  return (
    <div>
    <Texth1>Los famosos Posterpolos</Texth1>
    <Container>
      {popularProducts
      .filter((item, key) => 
      (item.category).includes("Polos") && (item.productLevel).includes("poster"))
      .map((item) => (
        <Product item={item} key={item.id} />
      ))}
    </Container>
    </div>
  );
};

export default ProductsPosterpolo;
