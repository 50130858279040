import React from "react";
import Announcement from "../components/Announcement";
import FooterLinks from "../components/Footer/FooterLinks";
import Social from "../components/Footer/SocialLinks";
import Copyright from "../components/Footer/Copyright";
import Navbar from "../components/Navbar";
import GoToTop from "../components/GoToTop";
import OptionsThanks from "../components/OptionsThanks";

const Thanks = () => {
  
  return (
    <div>
      <Announcement />
      <Navbar />
      <OptionsThanks />
      <Social />
      <FooterLinks />
      <Copyright />
      <GoToTop/>
    </div>
  );
};

export default Thanks;