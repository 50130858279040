import React from 'react';
import { useStateValue } from '../../StateProvider';
import "./ProductCart.css";

function ProductCart({id,productCod,title,theme,quantity,color,selectedsize,image,currency,price}) {
    const [{carrito}, dispatch] = useStateValue();

    const removeItem = () => {
        dispatch({
            type: 'REMOVE_FROM_CARRITO',
            id: id
        })
    }

    return (
        <div className="productCart" >
            <img className="productCart__image" src={image} alt="" />
            <div className="productCart__container">
            <div className="productCart__info">
                <p className="productCart__title">{title}</p>
                <p className="productCart__theme">"{theme}"</p>
                <p>Código: {productCod}</p>
                <p>Color: {color}</p>
                <p>Talla: {selectedsize}</p>
                <p>Cantidad: {quantity}</p>
                <p className="productCart__price">S/{price} cada uno</p>
            </div>
            <button onClick={removeItem} className="productCart__removebutton">Quitar del carrito.</button>
            </div>        
        </div>
    )
}

export default ProductCart;
