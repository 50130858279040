import React from 'react';
import { Link } from "react-router-dom";
import { LazyLoadComponent } from 'react-lazy-load-image-component';
/* import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; */
/* import {
  SearchOutlined,
  ShoppingCartOutlined,
} from "@material-ui/icons"; */
import styled from "styled-components";


const Info = styled.div`
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  transition: all 0.5s ease;
`;

const DetailProduct = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0 5px 0;
`;

const DetailTheme = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

const Image = styled.img`
  height: 75%;
  z-index: 2;
  transition: all 0.8s ease;
  border-radius: 20px;
`;

const Container = styled.div`
  flex: 1;
  margin: 5px;
  min-width: 280px;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.5s ease;
  transform: scale(1);
  
  &:hover ${Info}{
    opacity: 1;
    /* transition: all 1s ease;
    transform: scale(1.1); */
  }

  &:hover ${Image}{
    transition: all 1s ease;
    transform: scale(1.05);
  }
`;

const ProductRecommended = ({ item }) => {
  return (
    <LazyLoadComponent>
    <Container >
      <Image
      src={item.imageUrl}
      /* style={{ width: "100%", height: "auto", zIndex: 2, transition: "all 0.8s ease" }} */
      style={{ width:"300px", height: "auto", zIndex: 2, transition: "all 0.8s ease", borderRadius: "20px" }}
      loading="lazy" 
      decoding='async' 
      effect='blur' 
      alt={item.productTheme}
      placeholderSrc={process.env.PUBLIC_URL + "/android-chrome-192x192.png"} 
      />
      <DetailProduct> precio: S/{item.price} &bull; color: {item.color} </DetailProduct>
      <DetailTheme> "{item.productTheme}" </DetailTheme>
      
      <Link to={`/product/${item.productCod}`} >
      <Info>
        <button
        style={{
          backgroundColor:"white",
          color: "#111",
          padding: "10px 20px",
          border: "none",
          borderRadius: "20px",
          cursor: "pointer",
          marginTop:"20px"
        }}
        >
          ver detalle
        </button>
      </Info>
      </Link>

    </Container>
    </LazyLoadComponent>
  );
};

export default ProductRecommended;
