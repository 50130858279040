import React from "react";
import {Helmet} from "react-helmet";
import Announcement from "../components/Announcement";
import Suggestion from "../components/Suggestion";
import FooterLinks from "../components/Footer/FooterLinks";
import Social from "../components/Footer/SocialLinks";
import Copyright from "../components/Footer/Copyright";
import Navbar from "../components/Navbar";
import ProductsTshirt from "../components/ProductsTshirt";
import ProductsHotsale from "../components/ProductsHotsale";
import ProductsPosterpolo from "../components/ProductsPosterpolo";
import Slider from "../components/Slider";

const Home = () => {

  
  return (
    <div>
      <Helmet>
        <title>Fan Store de tus Series y Pelis favoritas.</title>
        <meta name='description' content="Tienda de TELEBOING para expresar tus sentimientos con respecto a las series y películas que amas y que te aman." data-react-helmet="true" />
        <meta name='image' content="https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing-shopcover1a.webp?alt=media&token=0c40e188-8a46-446d-ab20-52757814f224" data-react-helmet="true" />
        <link rel="canonical" href="https://shop.teleboing.com" />
        <meta property='image' content="https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing-shopcover1a.webp?alt=media&token=0c40e188-8a46-446d-ab20-52757814f224" data-react-helmet="true" />
        <meta property="og:title" content="Fan Store de tus Series y Pelis favoritas." data-react-helmet="true" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing-shopcover1a.webp?alt=media&token=0c40e188-8a46-446d-ab20-52757814f224" data-react-helmet="true" />
        <meta property="og:url" content="https://store.teleboing.com" />
        <meta name="twitter:card" content="summary_large_image"/>
        <meta property="og:description" content="Tienda de TELEBOING para expresar tus sentimientos con respecto a las series y películas que amas y que te aman." data-react-helmet="true" />
        <meta property="og:site_name" content="TELEBOING store"/>
        <meta name="twitter:image:alt" content="Fan Store de tus Series y Pelis favoritas." />
      </Helmet>
      <Announcement />
      <Navbar />
      <Slider />
      <ProductsHotsale />
      <Suggestion />
      <ProductsPosterpolo />
      <Suggestion />
      <ProductsTshirt />
      <Suggestion />
      <Social />
      <FooterLinks />
      <Copyright />
    </div>
  );
};

export default Home;
