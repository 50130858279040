import React from "react";
import "./OptionsThanks.css";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@material-ui/icons";


const OptionsThanks = () => {
  return (
     
    <header className="thanks__banner"
      style={{
      backgroundSize: "cover",
      backgroundImage: `linear-gradient(to top, black 10%, transparent 90%), linear-gradient(to right, #00001b, transparent), 
            url(
          "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcontent%2FTLBNGbrand_tshirt.webp?alt=media&token=b64a87c9-37c9-4b4a-9e58-43d0d5bf73bf"
      )`, 
      backgroundPosition: "top center",
    }}>

    <div className="thanks__content">

    <div className='thanks__content__back'>
      <ArrowLeftOutlined/>
      <a href="/" target={"_self"} rel="noreferrer">Volver a la tienda.</a>
    </div>
    <div>
    <div className='thanks__content__delivery' >
      <h1>¡Gracias por tu confianza! </h1>
      <p>Hemos recibido tu pedido y TELEBOING ya lo está preparando.</p>
    </div>
    </div>
    <div className='thanks__content__go' >
      <a href="https://teleboing.com" target={"_self"} rel="noreferrer">Mirar TELEBOING tv.</a>
      <ArrowRightOutlined/>
    </div>

    </div>
    </header>

  );
};

export default OptionsThanks;
