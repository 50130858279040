import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import Home from "./pages/Home";
import Product from "./pages/Product";
import Checkout from "./pages/Checkout";
import Thanks from "./pages/Thanks";


const App = () => {
  return (
  <div className="app">
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/product/:code" element={<Product />} />
        <Route exact path="/checkout" element={<Checkout />} />
        <Route exact path="/thanks" element={<Thanks />} />
        </Routes>
        
      </Router>
    </div>
    );
}

export default App;