import React from 'react';
import { useStateValue } from '../StateProvider';
import { Link } from "react-router-dom";
import "./Checkout.css";
import Announcement from "../components/Announcement";
import FooterLinks from "../components/Footer/FooterLinks";
import Social from "../components/Footer/SocialLinks";
import Copyright from "../components/Footer/Copyright";
import Navbar from "../components/Navbar";
import Subtotal from "../components/ShoppingCart/Subtotal";
import ProductCart from '../components/ShoppingCart/ProductCart';
import ReturnPolicy from '../components/ReturnPolicy';
import GoToTop from "../components/GoToTop";

function Checkout() {

    const [{carrito}] = useStateValue();
    console.log(carrito);

    return (
        <div>
            <Announcement />
            <Navbar />
            <div className="checkout" >
                <div className="checkout__left">
                    {
                        carrito.length === 0 ? (
                        <div>
                        <h2 className="checkout__left__untitle">Tu carrito de compras está vacío.</h2>
                        <p className="checkout__left__unsubtitle">No tienes ningún producto en el carrito. <Link to={"/"} style={{ textDecoration:"none", fontWeight:"500" }} >¡Elige uno aquí!.</Link></p>
                        </div>
                        ) : (
                            <div>
                            <h2 className="checkout__left__title">¡Excelente decisión!</h2>
                            <h3 className="checkout__left__subtitle">Estos son los productos que comprarás</h3>
                            {
                            carrito.map((item, key) => (
                                <div>
                                <ProductCart
                                    id={item.id}
                                    productCod= {item.productCod}
                                    title= {item.title}
                                    theme= {item.theme}
                                    quantity= {item.quantity}
                                    color= {item.color}
                                    selectedsize= {item.size}
                                    image= {item.image}
                                    currency= {item.currency}
                                    price= {item.price}
                                />
                                </div>
                                ))
                            }
                            </div>
                        )
                    }
                </div>

                {
                    carrito.length > 0 && (
                        <div className="checkout__right">
                            <Subtotal/>
                        </div>
                    )    
                }
            </div>
            <ReturnPolicy />
            <Social className="tohide" />
            <FooterLinks className="tohide" />
            <Copyright className="tohide" />
            <GoToTop/>
        </div>
    )
}

export default Checkout;
