export const sliderItems = [
    {
      id: 1,
      img: "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing-shopcover1a.webp?alt=media&token=0c40e188-8a46-446d-ab20-52757814f224",
      title: "COLECCIÓN POSTERPOLO",
      desc: "DISEÑOS CREADOS POR FANS DE TUS SERIES Y PELAS FAVORITAS.",
      bg: "f5fafd",
    },
    {
      id: 2,
      img: "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing-shopcover2.webp?alt=media&token=536a132b-015c-49e7-9640-6cefa5573614",
      title: "COLECCIÓN POSTERPOLO",
      desc: "DISEÑOS CREADOS POR FANS DE TUS SERIES Y PELAS FAVORITAS.",
      bg: "fcf1ed",
    },
    {
      id: 3,
      img: "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing-shopcover3.webp?alt=media&token=535f6bb3-2ffb-46ca-97ba-25eb8efd9525",
      title: "COLECCIÓN POSTERPOLO",
      desc: "DISEÑOS CREADOS POR FANS DE TUS SERIES Y PELAS FAVORITAS.",
      bg: "fbf0f4",
    },
  ];


  export const popularProducts = [
    {
        "id": "20",
        "productCod": "PE010050001",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "Daly - Money Heist",
        "alterName": "T-shirt",
        "description": "Money Heist o La Casa de Papel: Una banda organizada de ladrones se propone cometer el atraco del siglo en la Fábrica Nacional de Moneda y Timbre. Cinco meses de preparación quedarán reducidos a once días para poder llevar a cabo con éxito el gran golpe.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Black",
        "size": "XS, S, M, L, XL, XXL",
        "price": "69",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-6-1.webp?alt=media&token=02e5a236-c3fe-4c17-b56b-79ee95063403",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-6-2.webp?alt=media&token=b34b9510-d7cc-42bb-8bd7-9cdca0da83e2",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-6-3.webp?alt=media&token=bbc5aafa-acd2-43c0-b42a-33036ae37549",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "collection",
        "Hotsale": "True"
    },
    {
        "id": "21",
        "productCod": "PE010050002",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "Daly - Money Heist",
        "alterName": "T-shirt",
        "description": "Money Heist o La Casa de Papel: Una banda organizada de ladrones se propone cometer el atraco del siglo en la Fábrica Nacional de Moneda y Timbre. Cinco meses de preparación quedarán reducidos a once días para poder llevar a cabo con éxito el gran golpe.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Aqua Green",
        "size": "S, M, L, XL",
        "price": "59",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-aquagreen-6-1.webp?alt=media&token=3a9f1168-0eb1-4b15-946e-b8e4ed949f71",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-aquagreen-6-2.webp?alt=media&token=4f88cc1c-ba2a-4da7-8b33-a7b0e0fdc354",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-aquagreen-6-3.webp?alt=media&token=2507c596-662a-4444-984a-051bf9658746",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "collection",
        "Hotsale": "False"
    },
    {
        "id": "22",
        "productCod": "PE010050003",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "Daly - Money Heist",
        "alterName": "T-shirt",
        "description": "Money Heist o La Casa de Papel: Una banda organizada de ladrones se propone cometer el atraco del siglo en la Fábrica Nacional de Moneda y Timbre. Cinco meses de preparación quedarán reducidos a once días para poder llevar a cabo con éxito el gran golpe.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Beige",
        "size": "S, M, L, XL",
        "price": "59",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-beige-6-1.webp?alt=media&token=76b20186-f48f-459b-af88-c1a4bdbb2903",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-beige-6-2.webp?alt=media&token=da718ba8-bb4e-4c62-a130-06ae7e16e2d8",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-beige-6-3.webp?alt=media&token=2bb4dc7b-66dc-4620-bff2-f9e3b26dc7fe",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "collection",
        "Hotsale": "False"
    },
    {
        "id": "23",
        "productCod": "PE010050004",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "Daly - Money Heist",
        "alterName": "T-shirt",
        "description": "Money Heist o La Casa de Papel: Una banda organizada de ladrones se propone cometer el atraco del siglo en la Fábrica Nacional de Moneda y Timbre. Cinco meses de preparación quedarán reducidos a once días para poder llevar a cabo con éxito el gran golpe.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "White",
        "size": "XS, S, M, L, XL, XXL",
        "price": "49",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-white-6-1.webp?alt=media&token=5dae83d8-034d-4c5e-9e5d-3e9d741ce8e4",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-white-6-2.webp?alt=media&token=7b8c15e4-04c9-4b32-8493-e796194acf9a",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-white-6-3.webp?alt=media&token=36de0cfb-bdbb-4dec-b7d9-b98b46d73827",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "collection",
        "Hotsale": "False"
    },
    {
        "id": "24",
        "productCod": "PE010050005",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "Daly - Money Heist",
        "alterName": "T-shirt",
        "description": "Money Heist o La Casa de Papel: Una banda organizada de ladrones se propone cometer el atraco del siglo en la Fábrica Nacional de Moneda y Timbre. Cinco meses de preparación quedarán reducidos a once días para poder llevar a cabo con éxito el gran golpe.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Candy Blue",
        "size": "S, M, L, XL",
        "price": "59",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-CandyBlue-6-1.webp?alt=media&token=39015bc7-1d40-40cd-926a-a1636c33173c",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-CandyBlue-6-2.webp?alt=media&token=659f270d-ae55-43c3-8976-0cd7a4af4460",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-CandyBlue-6-3.webp?alt=media&token=780598df-b689-4bce-98f1-eea505649b56",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "collection",
        "Hotsale": "False"
    },
    {
        "id": "25",
        "productCod": "PE010050006",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "Daly - Money Heist",
        "alterName": "T-shirt",
        "description": "Money Heist o La Casa de Papel: Una banda organizada de ladrones se propone cometer el atraco del siglo en la Fábrica Nacional de Moneda y Timbre. Cinco meses de preparación quedarán reducidos a once días para poder llevar a cabo con éxito el gran golpe.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Pink",
        "size": "S, M, L, XL",
        "price": "59",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-rosa-6-1.webp?alt=media&token=b87ae4cb-beb6-4765-9283-fb307d03f8b9",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-rosa-6-2.webp?alt=media&token=3314a79a-8415-4237-915a-e81bd8a1e973",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-rosa-6-3.webp?alt=media&token=0ccf45ca-ada2-40b1-b8a4-0f78a69c50dd",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "collection",
        "Hotsale": "False"
    },
    {
        "id": "26",
        "productCod": "PE010050007",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "Daly - Money Heist",
        "alterName": "T-shirt",
        "description": "Money Heist o La Casa de Papel: Una banda organizada de ladrones se propone cometer el atraco del siglo en la Fábrica Nacional de Moneda y Timbre. Cinco meses de preparación quedarán reducidos a once días para poder llevar a cabo con éxito el gran golpe.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Royal Blue",
        "size": "S, M, L, XL",
        "price": "59",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-RoyalBlue-6-1.webp?alt=media&token=36a49abe-6b89-4097-9196-d20936c83078",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-RoyalBlue-6-2.webp?alt=media&token=0717c39f-e4b7-4577-8913-2130f614ddeb",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-RoyalBlue-6-3.webp?alt=media&token=e74400ce-3cee-427c-8ebc-8c9a2c68851b",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "collection",
        "Hotsale": "False"
    },
    {
        "id": "27",
        "productCod": "PE010050008",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "Daly - Money Heist",
        "alterName": "T-shirt",
        "description": "Money Heist o La Casa de Papel: Una banda organizada de ladrones se propone cometer el atraco del siglo en la Fábrica Nacional de Moneda y Timbre. Cinco meses de preparación quedarán reducidos a once días para poder llevar a cabo con éxito el gran golpe.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Sky Blue",
        "size": "S, M, L, XL",
        "price": "59",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-RoyalBlue-6-1.webp?alt=media&token=36a49abe-6b89-4097-9196-d20936c83078",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-RoyalBlue-6-2.webp?alt=media&token=0717c39f-e4b7-4577-8913-2130f614ddeb",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-RoyalBlue-6-3.webp?alt=media&token=e74400ce-3cee-427c-8ebc-8c9a2c68851b",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "collection",
        "Hotsale": "False"
    },
    {
        "id": "35",
        "productCod": "PE010080001",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "IT Clown",
        "alterName": "T-shirt",
        "description": "Pennywise, el payaso bailarín, también llamado Eso (en inglés, It), es un personaje ficticio. Varios niños de una pequeña ciudad del estado de Maine se alían para combatir a una entidad diabólica que adopta la forma de un payaso y desde hace mucho tiempo emerge cada 27 años para saciarse de sangre infantil.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Black",
        "size": "XS, S, M, L, XL, XXL",
        "price": "69",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-9-1.webp?alt=media&token=a516346b-3444-43c7-b6a9-26141da808e3",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-9-2.webp?alt=media&token=89794c58-8e71-40b4-b063-bc7b09fac4bb",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-9-3.webp?alt=media&token=9ef1f5cb-36da-4c5e-ad37-57257cb747ee",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "collection",
        "Hotsale": "False"
    },
    {
        "id": "36",
        "productCod": "PE010080002",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "IT Clown",
        "alterName": "T-shirt",
        "description": "Pennywise, el payaso bailarín, también llamado Eso (en inglés, It), es un personaje ficticio. Varios niños de una pequeña ciudad del estado de Maine se alían para combatir a una entidad diabólica que adopta la forma de un payaso y desde hace mucho tiempo emerge cada 27 años para saciarse de sangre infantil.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Beige",
        "size": "S, M, L, XL",
        "price": "59",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-beige-9-1.webp?alt=media&token=eb79455d-7ee4-4d20-81e3-447983bede25",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-beige-9-2.webp?alt=media&token=803b3c7e-7ce0-4120-bd6f-cbdabef369fe",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-beige-9-3.webp?alt=media&token=9a045c12-d8cf-4692-b52d-ce44889ead11",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "collection",
        "Hotsale": "False"
    },
    {
        "id": "37",
        "productCod": "PE010080003",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "IT Clown",
        "alterName": "T-shirt",
        "description": "Pennywise, el payaso bailarín, también llamado Eso (en inglés, It), es un personaje ficticio. Varios niños de una pequeña ciudad del estado de Maine se alían para combatir a una entidad diabólica que adopta la forma de un payaso y desde hace mucho tiempo emerge cada 27 años para saciarse de sangre infantil.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "White",
        "size": "XS, S, M, L, XL, XXL",
        "price": "49",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-white-9-1.webp?alt=media&token=f8751656-66c3-433e-ac40-56368dd32ce6",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-white-9-2.webp?alt=media&token=73396c36-1d7e-4374-b204-8a4be1036b66",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-white-9-3.webp?alt=media&token=b1d4fc65-a457-457a-8582-f4334a48d678",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "collection",
        "Hotsale": "False"
    },
    {
        "id": "38",
        "productCod": "PE010080004",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "IT Clown",
        "alterName": "T-shirt",
        "description": "Pennywise, el payaso bailarín, también llamado Eso (en inglés, It), es un personaje ficticio. Varios niños de una pequeña ciudad del estado de Maine se alían para combatir a una entidad diabólica que adopta la forma de un payaso y desde hace mucho tiempo emerge cada 27 años para saciarse de sangre infantil.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Candy Blue",
        "size": "S, M, L, XL",
        "price": "59",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-CandyBlue-9-1.webp?alt=media&token=751599e3-d1a7-40e0-91f5-ebdd7460b5ea",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-CandyBlue-9-2.webp?alt=media&token=5abfae74-708a-4e47-a83c-829a6b9cdcf2",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-CandyBlue-9-3.webp?alt=media&token=ef1edf2a-3dcb-4166-8c46-6402eaa50692",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "collection",
        "Hotsale": "False"
    },
    {
        "id": "39",
        "productCod": "PE010080005",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "IT Clown",
        "alterName": "T-shirt",
        "description": "Pennywise, el payaso bailarín, también llamado Eso (en inglés, It), es un personaje ficticio. Varios niños de una pequeña ciudad del estado de Maine se alían para combatir a una entidad diabólica que adopta la forma de un payaso y desde hace mucho tiempo emerge cada 27 años para saciarse de sangre infantil.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Pink",
        "size": "S, M, L, XL",
        "price": "59",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-rosa-9-1.webp?alt=media&token=ce265479-c805-49a2-b6bb-afd167600014",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-rosa-9-2.webp?alt=media&token=799450bc-72a8-4534-abf6-a818177aca5a",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-rosa-9-3.webp?alt=media&token=33738dd1-68df-4a46-bd22-2476f2f3ec2b",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "collection",
        "Hotsale": "False"
    },
    {
        "id": "40",
        "productCod": "PE010080006",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "IT Clown",
        "alterName": "T-shirt",
        "description": "Pennywise, el payaso bailarín, también llamado Eso (en inglés, It), es un personaje ficticio. Varios niños de una pequeña ciudad del estado de Maine se alían para combatir a una entidad diabólica que adopta la forma de un payaso y desde hace mucho tiempo emerge cada 27 años para saciarse de sangre infantil.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Royal Blue",
        "size": "S, M, L, XL",
        "price": "59",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-RoyalBlue-9-1.webp?alt=media&token=ef4e0b3d-eb0f-4f9e-83aa-e57785eeaf94",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-RoyalBlue-9-2.webp?alt=media&token=84e82926-a02e-4a0f-84e7-4ef9bfcddfb8",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-RoyalBlue-9-3.webp?alt=media&token=4fe0550b-8d9a-47c0-bdd9-49ff65b6679f",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "collection",
        "Hotsale": "False"
    },
    {
        "id": "41",
        "productCod": "PE010080007",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "IT Clown",
        "alterName": "T-shirt",
        "description": "Pennywise, el payaso bailarín, también llamado Eso (en inglés, It), es un personaje ficticio. Varios niños de una pequeña ciudad del estado de Maine se alían para combatir a una entidad diabólica que adopta la forma de un payaso y desde hace mucho tiempo emerge cada 27 años para saciarse de sangre infantil.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Sky Blue",
        "size": "S, M, L, XL",
        "price": "59",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-SkyBlue-9-1.webp?alt=media&token=32f69c3a-d03d-4a20-91fe-d53a8ba9ef9a",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-SkyBlue-9-2.webp?alt=media&token=f42e33d9-8ee5-4ff5-b87c-804a365fbadf",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-SkyBlue-9-3.webp?alt=media&token=6d8f7330-bd79-45de-a12a-5806a107990a",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "collection",
        "Hotsale": "False"
    },
    {
        "id": "42",
        "productCod": "PE010090001",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "The Purge - mask",
        "alterName": "T-shirt",
        "description": "Año 2022. En una futura sociedad despótica, el régimen político, llamado Nueva Fundación de los padres de América, ha decidido implantar una medida catártica: la purga anual, según la cual una noche al año se puede cometer cualquier clase de crimen, sin tener que responder ante la justicia.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Black",
        "size": "XS, S, M, L, XL, XXL",
        "price": "69",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-11-1.webp?alt=media&token=72ce7033-8da0-49d0-92e9-eea2bc44eed3",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-11-2.webp?alt=media&token=312675a9-2687-4051-9d21-dc87ad11edd1",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-11-3.webp?alt=media&token=930838ed-de44-44ea-a693-453d9d65bd11",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "collection",
        "Hotsale": "True"
    },
    {
        "id": "44",
        "productCod": "PE010110001",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "The Purge - red",
        "alterName": "T-shirt",
        "description": "Año 2022. En una futura sociedad despótica, el régimen político, llamado Nueva Fundación de los padres de América, ha decidido implantar una medida catártica: la purga anual, según la cual una noche al año se puede cometer cualquier clase de crimen, sin tener que responder ante la justicia.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Black",
        "size": "XS, S, M, L, XL, XXL",
        "price": "69",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-13-1.webp?alt=media&token=0e8dc0e9-8617-4dcf-9822-dc8800c034f1",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-13-2.webp?alt=media&token=0f3a6397-8929-407a-96a3-2d34865ed11c",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-13-3.webp?alt=media&token=a0fee024-ae54-47f6-97de-ad4aa6d4533c",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "collection",
        "Hotsale": "True"
    },
    {
        "id": "45",
        "productCod": "PE010120001",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "Squid game - icon",
        "alterName": "T-shirt",
        "description": "La serie narra la historia de 456 personas que deciden convertirse en jugadores de una serie de misteriosos y enfermizos juegos infantiles de supervivencia mortal para tener la oportunidad de ganar la cantidad máxima de 45 600 millones de wones. Por cada persona que muere se añade 100 millones de wones al bote, incitando el conflicto entre los jugadores.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Black",
        "size": "XS, S, M, L, XL, XXL",
        "price": "69",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-14-1.webp?alt=media&token=3784a8c3-b689-434e-8b2b-b1e3e8b7415a",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-14-2.webp?alt=media&token=1890352b-d663-4d81-b443-14a7785c9842",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-14-3.webp?alt=media&token=cd334094-b907-4c03-bc30-fd3350ef0cab",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "collection",
        "Hotsale": "False"
    },
    {
        "id": "46",
        "productCod": "PE010120002",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "Squid game - icon",
        "alterName": "T-shirt",
        "description": "La serie narra la historia de 456 personas que deciden convertirse en jugadores de una serie de misteriosos y enfermizos juegos infantiles de supervivencia mortal para tener la oportunidad de ganar la cantidad máxima de 45 600 millones de wones. Por cada persona que muere se añade 100 millones de wones al bote, incitando el conflicto entre los jugadores.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Beige",
        "size": "S, M, L, XL",
        "price": "59",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-beige-14-1.webp?alt=media&token=2e6766ac-a7bf-47de-8860-9bc9590bacc9",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-beige-14-2.webp?alt=media&token=2423ed5a-985d-4369-8cdf-4db4d57cab43",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-beige-14-3.webp?alt=media&token=e7c9f722-c201-44e8-92a0-f707abce0eac",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "collection",
        "Hotsale": "False"
    },
    {
        "id": "47",
        "productCod": "PE010120003",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "Squid game - icon",
        "alterName": "T-shirt",
        "description": "La serie narra la historia de 456 personas que deciden convertirse en jugadores de una serie de misteriosos y enfermizos juegos infantiles de supervivencia mortal para tener la oportunidad de ganar la cantidad máxima de 45 600 millones de wones. Por cada persona que muere se añade 100 millones de wones al bote, incitando el conflicto entre los jugadores.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "White",
        "size": "XS, S, M, L, XL, XXL",
        "price": "49",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-white-14-1.webp?alt=media&token=75d8fb89-175c-4d37-b610-ff068a766ee9",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-white-14-2.webp?alt=media&token=9781b626-eae1-4558-839b-2465a68d8729",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-white-14-3.webp?alt=media&token=9019ec06-f6f9-494e-828f-3bceba147120",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "collection",
        "Hotsale": "True"
    },
    {
        "id": "48",
        "productCod": "PE010120004",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "Squid game - icon",
        "alterName": "T-shirt",
        "description": "La serie narra la historia de 456 personas que deciden convertirse en jugadores de una serie de misteriosos y enfermizos juegos infantiles de supervivencia mortal para tener la oportunidad de ganar la cantidad máxima de 45 600 millones de wones. Por cada persona que muere se añade 100 millones de wones al bote, incitando el conflicto entre los jugadores.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Candy Blue",
        "size": "S, M, L, XL",
        "price": "59",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-CandyBlue-14-1.webp?alt=media&token=93c5a4ce-9b0f-434d-8f3b-c49b78bd6ebe",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-CandyBlue-14-2.webp?alt=media&token=6dbc1142-075a-43d9-ab64-7120e6288635",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-CandyBlue-14-3.webp?alt=media&token=a22e86e7-5a4e-4f6d-8c03-34dca650e12e",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "collection",
        "Hotsale": "False"
    },
    {
        "id": "49",
        "productCod": "PE010120005",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "Squid game - icon",
        "alterName": "T-shirt",
        "description": "La serie narra la historia de 456 personas que deciden convertirse en jugadores de una serie de misteriosos y enfermizos juegos infantiles de supervivencia mortal para tener la oportunidad de ganar la cantidad máxima de 45 600 millones de wones. Por cada persona que muere se añade 100 millones de wones al bote, incitando el conflicto entre los jugadores.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Dark Salmon",
        "size": "S, M, L, XL",
        "price": "59",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-DarkSalmon-14-1.webp?alt=media&token=76f7b819-9f72-4085-b167-cf0b40a2b611",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-DarkSalmon-14-2.webp?alt=media&token=e0406480-16ff-49d1-a385-7142b002612e",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-DarkSalmon-14-3.webp?alt=media&token=b1ee0159-5d4f-476d-b0c8-cba617a7ac60",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "collection",
        "Hotsale": "False"
    },
    {
        "id": "50",
        "productCod": "PE010120006",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "Squid game - icon",
        "alterName": "T-shirt",
        "description": "La serie narra la historia de 456 personas que deciden convertirse en jugadores de una serie de misteriosos y enfermizos juegos infantiles de supervivencia mortal para tener la oportunidad de ganar la cantidad máxima de 45 600 millones de wones. Por cada persona que muere se añade 100 millones de wones al bote, incitando el conflicto entre los jugadores.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Pink",
        "size": "S, M, L, XL",
        "price": "59",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-rosa-14-1.webp?alt=media&token=06520df6-00cb-4022-8ead-a5eb70f5380d",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-rosa-14-2.webp?alt=media&token=2a4790fd-ead1-41d2-ba9a-83a931cfab71",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-rosa-14-3.webp?alt=media&token=094e3420-f0f3-474e-90aa-529da4d90c37",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "collection",
        "Hotsale": "False"
    },
    {
        "id": "51",
        "productCod": "PE010120007",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "Squid game - icon",
        "alterName": "T-shirt",
        "description": "La serie narra la historia de 456 personas que deciden convertirse en jugadores de una serie de misteriosos y enfermizos juegos infantiles de supervivencia mortal para tener la oportunidad de ganar la cantidad máxima de 45 600 millones de wones. Por cada persona que muere se añade 100 millones de wones al bote, incitando el conflicto entre los jugadores.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Royal Blue",
        "size": "S, M, L, XL",
        "price": "59",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-RoyalBlue-14-1.webp?alt=media&token=6e7b0532-e8b5-4e7e-9850-f34781618ac5",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-RoyalBlue-14-2.webp?alt=media&token=df8f8d1d-aee2-4544-b5db-2e8c3f0762b8",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-RoyalBlue-14-3.webp?alt=media&token=ad7adcac-8642-437f-a165-3c4955b3c19d",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "collection",
        "Hotsale": "False"
    },
    {
        "id": "52",
        "productCod": "PE010120008",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "Squid game - icon",
        "alterName": "T-shirt",
        "description": "La serie narra la historia de 456 personas que deciden convertirse en jugadores de una serie de misteriosos y enfermizos juegos infantiles de supervivencia mortal para tener la oportunidad de ganar la cantidad máxima de 45 600 millones de wones. Por cada persona que muere se añade 100 millones de wones al bote, incitando el conflicto entre los jugadores.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Sky Blue",
        "size": "S, M, L, XL",
        "price": "59",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-SkyBlue-14-1.webp?alt=media&token=5a8184ef-0094-4b59-a8aa-816efd852add",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-SkyBlue-14-2.webp?alt=media&token=0fd35e9a-f30c-492e-b2ca-9f0b2f6e71ce",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-SkyBlue-14-3.webp?alt=media&token=90736278-2f4b-4875-a734-041c98ed3019",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "collection",
        "Hotsale": "False"
    },
    {
        "id": "59",
        "productCod": "PE020150001",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "Better Call Saul",
        "alterName": "T-shirt",
        "description": "\"Precuela de la serie \"\"Breaking Bad\"\", centrada en el personaje del abogado Saul Goodman (Bob Odenkirk), seis años antes de conocer a Walter White. La serie cuenta cómo un picapleitos de poca monta llamado Jimmy McGill, con problemas para llegar a fin de mes, se convierte en el abogado criminalista Saul Goodman.\"",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Black",
        "size": "XS, S, M, L, XL, XXL",
        "price": "69",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-17-1.webp?alt=media&token=ab9e2794-aa63-4aa9-8271-12a7be86e9ae",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-17-2.webp?alt=media&token=9998572c-64ac-4dae-85ac-57e53283e418",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-17-3.webp?alt=media&token=12f6e108-34c3-490d-8a22-bf67049390d6",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "poster",
        "Hotsale": "True"
    },
    {
        "id": "60",
        "productCod": "PE020160001",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "Boba Fett",
        "alterName": "T-shirt",
        "description": "El Libro de Boba Fett, una emocionante aventura de Star Wars, encuentra al legendario cazarrecompensas Boba Fett y a la mercenaria Fennec Shand navegando por el inframundo de la galaxia cuando regresan a las arenas de Tatooine para reclamar el territorio que una vez gobernó Jabba el Hutt y su sindicato criminal.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Black",
        "size": "XS, S, M, L, XL, XXL",
        "price": "69",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-18-1.webp?alt=media&token=af72a9d8-3637-42d2-b918-2474b127ba34",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-18-2.webp?alt=media&token=ddc26779-d7d9-47da-96d5-bd227714b0e9",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-18-3.webp?alt=media&token=585528ea-a8c0-4b9d-91d1-6c8778db6c55",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "poster",
        "Hotsale": "False"
    },
    {
        "id": "61",
        "productCod": "PE020170001",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "Money Heist",
        "alterName": "T-shirt",
        "description": "Money Heist o La Casa de Papel: Una banda organizada de ladrones se propone cometer el atraco del siglo en la Fábrica Nacional de Moneda y Timbre. Cinco meses de preparación quedarán reducidos a once días para poder llevar a cabo con éxito el gran golpe.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Black",
        "size": "XS, S, M, L, XL, XXL",
        "price": "69",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-19-1.webp?alt=media&token=08af07dd-a36c-4e7c-99dc-9628d79a401f",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-19-2.webp?alt=media&token=7c231c53-1b5a-4c23-bce6-ee1cc64e768e",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-19-3.webp?alt=media&token=1ce30736-72cc-454d-a2d9-d1513e9dd7b4",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "poster",
        "Hotsale": "True"
    },
    {
        "id": "62",
        "productCod": "PE020180001",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "Love Death + Robots",
        "alterName": "T-shirt",
        "description": "Cuando una misión de exploración a una luna de Júpiter termina en desastre, la única superviviente debe emprender un viaje tan peligroso como alucinante.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Black",
        "size": "XS, S, M, L, XL, XXL",
        "price": "69",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-20-1.webp?alt=media&token=f8eb7c55-eab5-493f-a0e7-ac18ea1bfbae",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-20-2.webp?alt=media&token=0c8c7555-bf46-4fe5-9e13-eb44e9488710",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-20-3.webp?alt=media&token=9dd6bf16-2741-41d0-a3e0-ee98c47e01a0",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "poster",
        "Hotsale": "False"
    },
    {
        "id": "63",
        "productCod": "PE020190001",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "The Mandalorian - Baby Yoda",
        "alterName": "T-shirt",
        "description": "The Mandalorian tiene lugar después de la caída del Imperio y antes de la aparición de la Primera Orden, cinco años después de los acontecimientos narrados en Return of the Jedi y sigue a un pistolero solitario en los confines de la galaxia, lejos de la autoridad de la Nueva República.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Black",
        "size": "XS, S, M, L, XL, XXL",
        "price": "69",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-21-1.webp?alt=media&token=4730fc90-8564-4b32-a427-5e921b82f10e",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-21-2.webp?alt=media&token=c59b3baa-6406-462a-a41c-ee75556c5b26",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-21-3.webp?alt=media&token=78e06550-3a2a-48c2-b0a2-55adf9620513",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "poster",
        "Hotsale": "True"
    },
    {
        "id": "64",
        "productCod": "PE020190002",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "The Mandalorian - This is the way",
        "alterName": "T-shirt",
        "description": "The Mandalorian tiene lugar después de la caída del Imperio y antes de la aparición de la Primera Orden, cinco años después de los acontecimientos narrados en Return of the Jedi y sigue a un pistolero solitario en los confines de la galaxia, lejos de la autoridad de la Nueva República.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Black",
        "size": "XS, S, M, L, XL, XXL",
        "price": "69",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-22-1.webp?alt=media&token=1cf64193-3808-4fe6-a3ee-16ae30c1200b",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-22-2.webp?alt=media&token=6ada1ef0-ea22-4399-826a-0bb6c5852bd9",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-22-3.webp?alt=media&token=c98755d0-4287-4690-b7c6-53427955d426",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "poster",
        "Hotsale": "False"
    },
    {
        "id": "65",
        "productCod": "PE020200001",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "Star Wars",
        "alterName": "T-shirt",
        "description": "Star Wars relata las vivencias de la familia Skywalker, hace mucho tiempo en una galaxia muy muy lejana, cuyos integrantes son capaces de percibir y utilizar La Fuerza, lo cual les permite desarrollar habilidades como la telequinesis, la clarividencia y el control mental, entre otras.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Black",
        "size": "XS, S, M, L, XL, XXL",
        "price": "69",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-23-1.webp?alt=media&token=945690ac-650b-440d-ac18-1e6793238342",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-23-2.webp?alt=media&token=2cfeb8f5-19ae-4446-9985-c9c0d884b504",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-23-3.webp?alt=media&token=8d7a8df3-003e-48cf-bf99-093eb822fbeb",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "poster",
        "Hotsale": "False"
    },
    {
        "id": "66",
        "productCod": "PE020210001",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "The Offer - Godfather",
        "alterName": "T-shirt",
        "description": "Las experiencias, nunca antes reveladas, del productor ganador del Oscar Albert S. Ruddy al producir la mítica película El padrino.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Black",
        "size": "XS, S, M, L, XL, XXL",
        "price": "69",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-24-1.webp?alt=media&token=f2fa5e30-69e6-43cf-908f-e041e9d29c82",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-24-2.webp?alt=media&token=e73276be-8663-41f1-a5a9-40e60ff160e4",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-24-3.webp?alt=media&token=677c3968-cc53-49e9-ad62-cd42e007334f",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "poster",
        "Hotsale": "True"
    },
    {
        "id": "67",
        "productCod": "PE020220001",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "Squid game - poster",
        "alterName": "T-shirt",
        "description": "La serie narra la historia de 456 personas que deciden convertirse en jugadores de una serie de misteriosos y enfermizos juegos infantiles de supervivencia mortal para tener la oportunidad de ganar la cantidad máxima de 45 600 millones de wones. Por cada persona que muere se añade 100 millones de wones al bote, incitando el conflicto entre los jugadores.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Black",
        "size": "XS, S, M, L, XL, XXL",
        "price": "69",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-25-1.webp?alt=media&token=265bad2b-304b-4197-8a6e-bb2f1ed99df7",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-25-2.webp?alt=media&token=16954b47-f23a-4c6a-a8c0-74d15051239a",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-25-3.webp?alt=media&token=9cee085a-54c9-4e70-8945-55e7fba553cc",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "poster",
        "Hotsale": "False"
    },
    {
        "id": "68",
        "productCod": "PE020230001",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "WandaVision - TV",
        "alterName": "T-shirt",
        "description": "WandaVision combina el estilo cómico de situaciones clásicas con el Universo Cinematográfico de Marvel en el que Wanda Maximoff y Visión, dos seres superpoderosos que viven su vida suburbana ideal, comienzan a sospechar que no todo es lo que parece.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Black",
        "size": "XS, S, M, L, XL, XXL",
        "price": "69",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-26-1.webp?alt=media&token=11462718-d934-40de-9934-98a97012dd92",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-26-2.webp?alt=media&token=47c8ff8d-d5be-4c8a-814c-292096e76d25",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-26-3.webp?alt=media&token=66c55e54-70f3-48b9-ae7c-3f1535c7f2fd",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "poster",
        "Hotsale": "False"
    },
    {
        "id": "69",
        "productCod": "PE020230002",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "WandaVision - Cinematic",
        "alterName": "T-shirt",
        "description": "WandaVision combina el estilo cómico de situaciones clásicas con el Universo Cinematográfico de Marvel en el que Wanda Maximoff y Visión, dos seres superpoderosos que viven su vida suburbana ideal, comienzan a sospechar que no todo es lo que parece.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Black",
        "size": "XS, S, M, L, XL, XXL",
        "price": "69",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-27-1.webp?alt=media&token=169bc6e8-77d1-4c03-85f1-c4dbead13f4c",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-27-2.webp?alt=media&token=78fdb512-db90-4b65-92ad-c31620681a4c",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-27-3.webp?alt=media&token=595036f1-a160-4947-8c07-986929d1bb24",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "poster",
        "Hotsale": "False"
    },
    {
        "id": "70",
        "productCod": "PE020240001",
        "category": "Polos",
        "gender": "Unisex",
        "productType": "Camiseta",
        "productName": "Polo de cuello redondo",
        "productTheme": "Heartstopper",
        "alterName": "T-shirt",
        "description": "Es la historia de amor entre dos chicos, uno de ellos declaradamente homosexual y el otro en vías de autodescubrimiento, nos recordará que hay primeros amores frágiles como el cristal y memorables como el diamante.",
        "productDetail": "",
        "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
        "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
        "color": "Black",
        "size": "XS, S, M, L, XL, XXL",
        "price": "69",
        "currency": "soles",
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-28-1.webp?alt=media&token=b15d4cbc-2c24-4d3c-95d7-1d58f934c85f",
        "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-28-2.webp?alt=media&token=3da56bea-3c2d-4070-b24c-8ca7d6ea1265",
        "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/shop-teleboing.appspot.com/o/resources%2Fcatalog%2Fproducts%2FPolo-black-28-3.webp?alt=media&token=e8c7f741-36d1-4fa5-956e-91e9bf57256e",
        "imageExtra3Url": "",
        "imageExtra4Url": "",
        "imageExtra5Url": "",
        "countInStock": "",
        "artBy": "",
        "productLevel": "poster",
        "Hotsale": "True"
    }
]