export const initialState = {
    carrito: [],
}

const reducer = (state, action) => {
    console.log(action);
    switch(action.type) {
        case 'ADD_TO_CARRITO':
            return {
                ...state,
                carrito: [...state.carrito, action.item]
            }
        
        case 'REMOVE_FROM_CARRITO':
            let newcart = [...state.carrito]
            const index = state.carrito.findIndex((carritoItem) => carritoItem.id === action.id)
            if (index >=0) {
                newcart.splice(index,1)
            } else {
                console.log("Hubieron errores al eliminar el producto del carrito.")
            }
            return {...state, carrito: newcart}
    }
}

export default reducer;